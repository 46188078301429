import React from "react";
 

import Layout from 'layouts/layout'
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";
import imgStyles from "assets/style/image.js"
import { Link } from "@material-ui/core";

function CommunityPage() {
  const UseStyles = makeStyles(styles);
  const UseImgStyles = makeStyles(imgStyles);

  const classes = UseStyles();
  const imgClass = UseImgStyles();

  return (
    <Layout>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} >
          {community && <div style={{ color: 'black', padding: '50px', margin: '10vw auto' }} >
            <h2>Wspólnota {community.name}</h2>
            <img src={`/zdjecia/Wspólnoty/${community.name}.jpg`} alt="" style={{ maxWidth: '100%' }} />
            <div style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-evenly' }}>
              <div >
                <img src={`/zdjecia/Księża/${community.priest}.jpg`} alt="" style={{ maxWidth: '80%', maxHeight: '200px', margin: '10% auto' }} />
                <h3 style={{ fontWeight: 'bold' }}>Opiekun: </h3>
                <h3>{community.priest}</h3>
              </div>
              <div >
                <img src={`/zdjecia/Liderzy/${community.lider}.jpg`} alt="" style={{ maxWidth: '80%', maxHeight: '200px', margin: '10% auto' }} />
                <h3 style={{ fontWeight: 'bold' }}>Lider: </h3>
                <h3>{community.lider}</h3>
              </div>
            </div>
            {community.meetings &&
              <>
                <h3 style={{ fontWeight: 'bold' }}>Spotkania: </h3>
                <h3>{community.meetings}</h3>
              </>
            }
            <h3 style={{ fontWeight: 'bold' }}>O wspólnocie: </h3>
            <h3>{community.description}</h3>
            {community.comming && community.comming.map((item, idx) => (
              <h3 key={idx}>{item}</h3>))}
            {community.news &&
              <>
                <h3>Aktualności: </h3>
                {community.news.map((item, idx) => (
                  <Link key={idx} href={`/${item.link}`} id={`#${item.link}`}>
                    <img src={`/${item.posterUrl}`} className={imgClass.img} alt={item.alt}/>
                  </Link>
                )
                )}
              </>
            }
          </div>}
        </div>
      </div>
    </Layout>
  );
}

 
  const community = {
    "url": "sw-michala",
    "name": "ŚW. MICHAŁA",
    "lider": "Alicja Cepil",
    "priest": "ks. Damian Cholewa",
    "meetings": "Parafia pw. Bożego Ciała w Oleśnie, poniedziałek godz. 19:00",
    "description": "Wspólnota zawiązała się jakiś czas temu przy Parafii Rzymskokatolickiej pw. Bożego Ciała w Oleśnie. Jest grupą, do której należą ludzie w różnym wieku i o różnych zainteresowaniach, ale łączy ich jedno: chęć umocnienia wiary oraz pragnienie nawiązania osobistej relacji z Bogiem. Początek dały spotkania o charakterze oazowym z ks. Sebastianem Krzyżanowskim. Potem „krokiem milowym' w rozwoju wspólnoty okazały się rekolekcje ewangelizacyjne pn. „Zacznij od nowa', prowadzone przez ks. Eugeniusza Plocha oraz świeckich animatorów Katolickiej Odnowy w Duchu Świętym diecezji opolskiej. Od tego momentu, czyli od października 2011 r. datujemy początek Wspólnoty. Obecnie grupa liczy ok. 30 osób. Ma cztery 3-osobowe zespoły animatorów, które przygotowują poszczególne spotkania, 4-osobową diakonię muzyczną i 4-osobową diakonię modlitwy wstawienniczej. Duchownym opiekunem Wspólnoty jest ks. Damian Cholewa, posługujący w Parafii pw. Bożego Ciała w Oleśnie jako wikariusz od roku 2017, a świeckim liderem grupy od listopada 2016 r.  jest Alicja Cepil."
  }
   

export default CommunityPage;
